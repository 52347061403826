//账户明细
<template>
  <div class="account_details">
    <!-- 视图部分 -->
    <Layout :showLoading="showLoading">
      <template v-slot:content>
        <div class="accont_details_content">
          <!-- 校长端显示课程余额的部分 展示屏蔽 v-if="role==3&&balanceList&&balanceList.length>0" -->
          <div class="top_lesson margin_left_14" v-if="false">
            <div class="item_lesson margin_right_10" v-for="(item, index) in balanceList" :key="index">
              <span class="red_text">{{ item.subName }}</span>
              <span>学生账户余额:{{ item.balance }}</span>
            </div>
          </div>

          <!-- 顶部操作栏 -->
          <div class="top_action margin_left_14">
            <!-- 上课日期 -->
            <div class="class_time">
              <span class="margin_right_10">时间：</span>

              <!-- 日期选择 :picker-options="pickerOptions" -->
              <el-date-picker
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
                v-model="chooseTime"
                type="daterange"
                range-separator="至"
                start-placeholder="开始月份"
                end-placeholder="结束月份"
              >
              </el-date-picker>
            </div>

            <!-- 学校名称关键字 -->
            <div class="school_search margin_left_30" v-if="role != 3">
              <span class="margin_right_4">学校：</span>
              <el-input clearable v-model="schoolName" placeholder="请输入学校名称关键字"></el-input>
            </div>

            <!-- 校长端按课程查询下拉框 -->
            <div class="search_lesson margin_left_30" v-if="role == 3 && subList && subList.length > 0">
              <span class="margin_right_4">课程：</span>
              <el-select @change="changeLesson" v-model="lessonValue" placeholder="请选择课程">
                <el-option v-for="item in subList" :key="item.subId" :label="item.subName" :value="item.subName"> </el-option>
              </el-select>
            </div>
          </div>

          <!-- 表格部分 -->
          <div class="dv_table_view_content">
            <!-- 表格部分 :style="{width:tableWidth+'px',margin:'auto'}" -->
            <el-table ref="table_ac_d" style="width: 98%; margin: auto" border stripe :data="tableData" :max-height="maxHeight">
              <!-- 序号  -->
              <el-table-column type="index" label="序号" align="center" width="60">
                <template scope="scope">
                  <span>{{ (currPage - 1) * pageSize + scope.$index + 1 }}</span>
                </template>
              </el-table-column>

              <!-- 时间  -->
              <el-table-column prop="create_time" align="center" :show-overflow-tooltip="true" label="时间" width="200"></el-table-column>

              <!-- 学校简称  -->
              <el-table-column prop="school_name" align="center" :show-overflow-tooltip="true" label="学校简称" width="180"></el-table-column>

              <!-- 操作 -->
              <el-table-column align="center" label="操作" width="140">
                <template slot-scope="scope">
                  <!-- 新增 type==1 -->
                  <div v-if="scope.row.type == 1">
                    <span class="tv_red">+新增</span>
                  </div>

                  <!-- 消耗 type==0 -->
                  <div v-else-if="scope.row.type == 0">
                    <span class="tv_green">-消耗</span>
                  </div>

                  <!-- 失效 type==2 -->
                  <div v-else-if="scope.row.type == 2">
                    <span class="tv_red">失效</span>
                  </div>

                  <!-- 恢复 type==3 -->
                  <div v-else-if="scope.row.type == 3">
                    <span class="tv_red">恢复</span>
                  </div>

                  <!-- 修改期限 type==4 -->
                  <div v-else-if="scope.row.type == 4">
                    <span class="tv_red">修改期限</span>
                  </div>
                </template>
              </el-table-column>

              <!-- 操作描述  -->
              <el-table-column align="center" label="操作描述" min-width="20%">
                <template slot-scope="scope">
                  <!-- 新增 type==1 -->
                  <div class="tv_small" v-if="scope.row.type == 1">
                    <span>新增了{{ scope.row.num }}个</span><span class="tv_red">{{ scope.row.sub_name }}{{ scope.row.level_name }}</span
                    >{{ scope.row.role == 0 ? "学生" : "老师" }}账号({{ scope.row.end_date }}之前有效)
                  </div>

                  <!-- 消耗 type==0 -->
                  <div class="tv_small" v-else-if="scope.row.type == 0">
                    <span>消耗了{{ scope.row.num }}个</span><span class="tv_red">{{ scope.row.sub_name }}{{ scope.row.level_name }}</span
                    >{{ scope.row.role == 0 ? "学生" : "老师" }}账号({{ scope.row.end_date }}之前有效)
                  </div>

                  <!-- 失效 type==2 -->
                  <div class="tv_small" v-else-if="scope.row.type == 2">
                    <span class="tv_red">{{ scope.row.sub_name }}{{ scope.row.level_name }}</span>
                    <span>失效了{{ scope.row.num }}个</span>{{ scope.row.role == 0 ? "学生" : "老师" }}账号({{ scope.row.end_date }}之前有效)
                  </div>

                  <!-- 恢复 type==3 -->
                  <div class="tv_small" v-else-if="scope.row.type == 3">
                    <span>恢复了</span>
                    <span class="tv_red">{{ scope.row.sub_name }}{{ scope.row.level_name }}</span>
                    <span>{{ scope.row.num }}个</span>{{ scope.row.role == 0 ? "学生" : "老师" }}账号({{ scope.row.end_date }}之前有效)
                  </div>

                  <!-- 修改 type==4 -->
                  <div class="tv_small" v-else-if="scope.row.type == 4">
                    <span>修改了</span>
                    <span class="tv_red">{{ scope.row.sub_name }}{{ scope.row.level_name }}</span
                    ><span>{{ scope.row.num }}个</span>{{ scope.row.role == 0 ? "学生" : "老师" }}账号的使用期限(由{{ scope.row.old_start_date }}~{{
                      scope.row.old_end_date
                    }}修改为{{ scope.row.start_date }}~{{ scope.row.end_date }})
                  </div>
                </template>
              </el-table-column>

              <!-- 操作人  -->
              <el-table-column align="center" label="操作人" width="240">
                <template slot-scope="scope">
                  <div>
                    <!-- 有操作人 -->
                    <span v-if="scope.row.operator_name"
                      >{{ scope.row.operator_name
                      }}<span class="margin_left_6" v-if="scope.row.tel"
                        >(<span class="tv_red">{{ scope.row.tel }}</span
                        >)</span
                      ></span
                    >
                    <!-- 没有操作人 -->
                    <span v-else>-</span>
                  </div>
                </template>
              </el-table-column>
            </el-table>

            <!-- 顶部的分页器部分 -->
            <div class="bom_page margin_bom_20" v-if="tableData && tableData.length > 0">
              <el-pagination
                background
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="currPage"
                :page-sizes="pageSizeArr"
                :page-size="pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="totalSize"
              >
              </el-pagination>
            </div>
          </div>
        </div>
      </template>
    </Layout>
  </div>
</template>
<script>
export default {
  data() {
    return {
      //是否显示加载框
      showLoading: -1,

      //选择的日期
      chooseTime: "",

      //日期选择的配置项
      pickerOptions: {
        //设置禁用日期,2022年1月1日之前的不允许选择,最多选择到当前日期
        disabledDate(time) {
          // let dates = new Date("2021-12-31");
          return time.getTime() <= dates.getTime() || time.getTime() >= new Date().getTime();
        },
      },

      //搜索学校名称关键字
      schoolName: "",

      //当前页面显示的列表数据
      tableData: [],

      //总的数据条数
      totalSize: "",

      //条数选择的数据
      pageSizeArr: [8, 10, 12],

      //每页显示的条数
      pageSize: 10,

      //当前显示的页码
      currPage: 1,

      //表格的宽度
      tableWidth: 0,

      //表格的最大高度
      maxHeight: 0,

      //搜索的其实日期
      start_date: "",
      end_date: "",
      //科目id
      subId: "",
      //当前登录账号的角色类型
      role: "",
      //校长端用于展示课程余额的数据
      balanceList: [],

      //校长端选择的课程
      lessonValue: "",

      //记录上次选择的课程
      recordLessonValue: "",

      //校长端选择的课程集合
      subList: [],
    };
  },
  created() {
    this.initData();

    //校长端才去查询课程信息请求数据
    this.httpData();
  },

  mounted() {},
  methods: {
    //初始化表格的最大高度
    initData() {
      //获取用户角色
      this.role = this.getUserRole();
      let clientH = document.documentElement.clientHeight;
      if (this.role == 3) {
        //校长端
        this.maxHeight = clientH - 210;
      } else {
        this.maxHeight = clientH - 210;
      }
    },

    /**
     * type 20
     * sDate开始时间 格式：yyyy-MM-dd（可选）
     * eDate 结束时间，格式：yyyy-MM-dd（可选）
     * subId 科目id（可选）
     * sname 校区名称（可选）
     * page 分页页码，数字类型
     * pagecount 每页数量
     */
    httpData() {
      let param = this.getHttpParams();
      param.type = 20;
      param.page = this.currPage - 1;
      param.pagecount = this.pageSize;
      //sDate开始时间
      if (!this.isEmpty(this.start_date)) {
        param.sDate = this.start_date;
      } else {
        param.sDate = "";
      }

      //eDate 结束时间
      if (!this.isEmpty(this.end_date)) {
        param.eDate = this.end_date;
      } else {
        param.eDate = "";
      }

      //科目id
      if (!this.isEmpty(this.lessonValue)) {
        param.subId = this.getSubId();
      } else {
        param.subId = "";
      }

      //校区名称
      if (!this.isEmpty(this.schoolName)) {
        param.sname = this.schoolName;
      } else {
        param.sname = "";
      }

      this.$http
        .fetchPost(this.$api.ACCOUNTDETAILS, param)
        .then((res) => {
          //显示内容视图
          this.showLoading = 1;
          let result = res.data;
          console.log("请求到的数据是:", result);
          //将表格滚动到顶部
          this.$nextTick(() => {
            this.$refs.table_ac_d.bodyWrapper.scrollTop = 0;
          });
          if (result.state == 200) {
            //只有校长端的时候
            if (this.role == 3) {
              if (!this.isEmpty(result.data.balanceList)) {
                this.balanceList = result.data.balanceList;
              }

              if (!this.isEmpty(result.data.subList)) {
                this.subList = result.data.subList;
              }
            }

            //获取返回对象
            let detailList = result.data.detailList;
            if (!this.isEmpty(detailList)) {
              //获取总条数
              this.totalSize = Number.parseInt(detailList.totalNum);
              //先清空一下表格数据
              this.tableData.splice(0, this.tableData.length);
              if (this.totalSize != 0) {
                //获取列表数据
                this.tableData = detailList.infoList;
                //组装一下表格数据
                this.tableData.forEach((item, index) => {
                  //序号
                  this.$set(item, "index", index + 1);
                });
              }
            }
          } else {
            this.showErrorMsg(this.getMesage(res.data.state));
          }
        })
        .catch((err) => {
          this.showLoading = 1;
          console.log("请求数据异常");
        });
    },

    //每页显示的条数变化时触发
    handleSizeChange(val) {
      this.pageSize = val;
      // console.log("当前页显示的条数是:", this.pageSize);
      //重新刷新数据
      this.httpData();
    },

    //页面改变时触发
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      this.currPage = val;
      //重新刷新数据
      this.httpData();
    },

    //课程选中后的回调
    changeLesson(val) {
      // console.log("触发选中事件了", val, this.lessonValue);
      if (this.recordLessonValue != val) {
        //记录课程的选中的值
        this.recordLessonValue = val;

        //切换页码
        this.currPage = 1;

        //重新刷新数据
        this.httpData();
      }
    },

    //根据选中的课程名称获取subId
    getSubId() {
      let obj = this.subList.find((item) => {
        return item.subName == this.lessonValue;
      });
      return obj.subId;
    },
  },
  computed: {},
  components: {},
  watch: {
    //监听学校编码输入值的变化
    schoolName(newValue, oldValue) {
      if (newValue != oldValue) {
        //刷新页面数据
        this.currPage = 1;
        this.httpData();
      }
    },

    //选择时间的监听
    chooseTime(newValue, oldValue) {
      if (!this.isEmpty(newValue)) {
        this.start_date = this.chooseTime[0];
        this.end_date = this.chooseTime[1];
        // console.log("选择时间是:", this.start_date, this.end_date);
        //判断一下选择的时间间隔,最小1天,最大12个月
        if (!this.isRightTimeRange(this.start_date, this.end_date)) {
          this.showWarnMsg("选择的时间间隔必须大于1天");
          return;
        }
        //判断一下选择的时间间隔是否超过12个月
        if (this.isOverTimeRange(this.startTime, this.endTime)) {
          this.showWarnMsg("选择的时间间隔最大为12个月");
          return;
        }
      } else {
        this.start_date = "";
        this.end_date = "";
      }
      //还原页码重新请求数据
      this.currPage = 1;
      this.httpData();
    },
  },
};
</script>
<style lang="scss" scoped>
.account_details {
  height: calc(100vh - 78px);
  display: flex;
  flex-direction: column;
  //校长端显示课程余额
  .top_lesson {
    height: 50px;
    display: flex;
    justify-content: start;
    align-items: center;

    .item_lesson {
      padding: 6px 10px;
      border-radius: 4px;
      background: #cfd1d4;
    }
  }

  //顶部操作栏
  .top_action {
    height: 60px;
    display: flex;
    align-items: center;

    //时间
    .class_time {
      display: flex;
      align-items: center;

      ::v-deep .el-input__inner {
        height: 30px;
        line-height: 30px;
        padding-top: 0;
        padding-bottom: 0;
        width: 280px;
      }
      ::v-deep .el-range-separator {
        line-height: 30px;
        margin-right: 6px;
        margin-left: 2px;
      }

      ::v-deep .el-range__icon {
        line-height: 30px;
      }
    }

    //学校
    .school_search {
      display: flex;
      align-items: center;
      justify-content: center;

      ::v-deep .el-input__inner {
        height: 30px;
        line-height: 30px;
        padding-left: 6px;
      }

      ::v-deep .el-input__icon {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      ::v-deep .el-input {
        width: 210px;
      }

      ::v-deep .el-input__suffix {
        right: 0px;
      }
    }

    //课程
    .search_lesson {
      ::v-deep .el-input__inner {
        height: 30px;
        line-height: 30px;
      }

      ::v-deep .el-input__icon {
        line-height: 30px;
      }
    }
  }

  .tv_small {
    font-size: 12px;
  }

  .dv_table_view_content {
    height: 100%;
    margin-top: 0;
  }
}
</style>
